import React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";

import zuerichCityImageUrl from "../images/ZuerichStadt.jpg";

const Toolbar = styled.div`
  display: flex;
  flex-direction: row;
  @media(max-width: 1300px) {
    flex-direction: column !important;
  }
`;
const Text = styled.p`
  padding: 0px 10px 0px 10px;
  line-height: 150%;
`;
const Image = styled.img`
  width: 50%;
  float: left;
  height: 60vh;
  object-fit: cover;
  @media(max-width: 1300px) {
    width: 100% !important;
  }
`;
const Corner = styled.div`
  margin-right: 15px;
  width: 50%;
  @media(max-width: 1300px) {
    width: 100% !important;
  }
`;

export default () => (
  <Layout>
    <Toolbar>
      <Corner>
        <h1>Unser Unternehmen</h1>
        <Text>Die langjährige Erfahrung im Versicherungs-, Finanzwesen und Vorsorge ermöglicht uns, intensiv auf Ihre Bedürfnisse und Ziele einzugehen.</Text>
        <Text>Damit wird sichergestellt das Ihre Bedürfnisse und Ziele erreicht werden.</Text>
        <Text>Unser dynamisches Team ist bereit sich jederzeit für Sie einzusetzen und Sie zu vertreten. Unser Team besteht aus qualifizierten Beratern, welche Sie vor Ort persönlich und zuverlässig betreuen.</Text>
        <Text>Unsere Vision ist es, der weitverbreiteste und vertrauensvollste Ansprechspartner der ganzen Schweiz zu werden, dies wenn es um Versicherungen, Vorsorge, Kredite, Immobilien, Verwaltung, Büroservices und Unternehmungen geht. </Text>
        <Text>Die Dienste , die wir anbieten umfassen ein grosses Spektrum von Know How und Vernetzung. In der Zentralschweiz verankert, reicht unsere Umfangsweite bis ins Tessin. </Text>
        <Text>365 Tage stehen wir Ihnen gerne zur Verfügung.</Text>
      </Corner>
      <Image src={zuerichCityImageUrl} alt="Zürich Stadt" />
    </Toolbar>
  </Layout >
);
